/**
 * EpiFragments
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
	ResponsiveIframe,
	InfoBox,
	ImageModal,
	Quote,
	DescriptionList,
	DescriptionWrapper,
	DescriptionTerm,
	DescriptionText,
	LinkListItem,
	LinkList,
	Grid,
	Cell,
	Button,
	Icon,
} from 'ui-component-library/base';
import EkoForm from 'components/EkoForm/EkoForm/EkoForm';
import {
	StepByStepItem,
	QuestionList,
	QuestionListHeader,
	QuestionListItem,
	EpiForms,
	CriteriaLevelSelect,
	CriteriaLevel,
	InfoBoxCriteria,
	InfoBoxCriteriaItem,
	GuidanceBox,
	ReferenceAccordion,
} from 'ui-component-library/uhmse';
import { selectEpi } from 'store/modules/epi';
import {
	FragmentModelTypes,
	RawFragmentModel,
	HeadingFragmentModel,
	ImageFragmentModel,
	VideoFragmentModel,
	InfoBoxFragmentModel,
	ReferencesBoxFragmentModel,
	StepByStepModel,
	TableFragmentModel,
	RelatedDialogsModel,
	StepFragmentModel,
	FormContainerFragmentModel,
	ChapterFragmentModel,
	QuoteFragmentModel,
	DescriptionListFragmentModel,
	LinkListFragmentModel,
	RequirementLevelComparerModel,
	RequirementLevelRowModel,
	InfoBoxesModel,
	EmbeddedScriptModel,
	InfoBoxCriteriaModel,
	RiskAssessmentsMatrixModel,
	RiskAssessmentsMatrixSmallModel,
	HighchartsModel,
	ServiceButtonFragmentModel,
	StatisticsDataBlockModel,
	EmploymentRequirementsCalculationFormModel,
	StatisticsDataExportOptionModel,
	GuidanceInfoBoxFragmentModel,
	ReferencesAccordionFragmentModel,
} from 'types';
import StepByStep from 'components/StepByStep';
import Text from 'components/Text';
import RichText from 'components/RichText';
import Image from 'components/Image';
import clsx from 'clsx';
import Link from 'components/Link';
import EmbedScript from 'components/EmbedScript';
import RiskTable from 'components/RiskTable';
import StatisticTable from 'components/StatisticTable';
import ClimateImpactEvaluationForm from '../ClimateImpactEvaluationForm';
import StatisticsDataBlock from 'components/StatisticsDataBlock';
import StatisticChart from 'components/StatisticChart';
import ExportDataDropdown from 'components/ExportDataDropdown';
import ExportDataOption from 'components/ExportDataDropdown/ExportDataOption';
import { useLocation } from 'react-router-dom';
interface Props {
	/** Epi property to use when edit the text */
	prop?: string;

	/** Fragments that should be rendered. */
	fragments?: FragmentModelTypes[];

	/** Optional className */
	className?: string;
}

const getHeadingLevel = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
		default:
			return 'h2';
	}
};

/** Component for rendering Episerver fragments. */
const EpiFragments: React.FC<Props> = ({ prop, fragments, className }) => {
	let hashstring = useLocation().hash;

	const getFragment = (fragment: FragmentModelTypes, index: number) => {
		switch (fragment.modelType) {
			case 'EmbeddedScriptModel':
				const scriptData = fragment as EmbeddedScriptModel;
				return <EmbedScript key={index} src={scriptData.url} />;
			case 'Step':
				const stepData = fragment as StepFragmentModel;
				return (
					<div className="ml-20" key={index}>
						<StepByStepItem
							id={stepData.id}
							header={stepData.heading}
							open={
								(stepData.id && hashstring.includes(stepData.id)) ||
								stepData.isOpen
							}
							openText="Visa"
							closeText="Dölj"
							borderTop={true}
							className="pt-8"
						>
							<EpiFragments fragments={stepData.fragments} />
						</StepByStepItem>
					</div>
				);
			case 'StepByStep':
				return (
					<StepByStep
						key={index}
						steps={(fragment as StepByStepModel).steps}
						listType={(fragment as StepByStepModel).listType}
					/>
				);
			case 'HeadingFragment':
				const data = fragment as HeadingFragmentModel;
				return (
					<Text
						as={getHeadingLevel(data.level)}
						id={data.id && data.id !== '' ? data.id : null}
						key={index}
					>
						<span dangerouslySetInnerHTML={{ __html: data.raw }} />
					</Text>
				);

			case 'Image':
				const imgData = fragment as ImageFragmentModel;

				return (
					<React.Fragment key={index}>
						{imgData.zoom ? (
							<ImageModal
								openBtnLabel={imgData.openBtnLabel}
								closeBtnLabel={imgData.openBtnLabel}
							>
								<Image
									loading="lazy"
									src={imgData.src + '?width=684'}
									alt={imgData.alt}
									prop={imgData.prop}
									figCaption={imgData.caption}
									largeSrc={imgData.largeSrc}
									size={imgData.size}
									height={imgData.height}
									width={imgData.width}
								/>
							</ImageModal>
						) : (
							<Image
								loading="lazy"
								src={imgData.src + '?width=684'}
								alt={imgData.alt}
								prop={imgData.prop}
								figCaption={imgData.caption}
								size={imgData.size}
								height={imgData.height}
								width={imgData.width}
							/>
						)}
					</React.Fragment>
				);
			case 'Video':
				const videoData = fragment as VideoFragmentModel;
				const handleClick = () => {
					if (
						typeof window !== 'undefined' &&
						window.CookieConsent &&
						window.CookieConsent.renew
					) {
						window.CookieConsent.renew();
					}
				};

				var src = '';
				if (videoData.embedUrl.includes('youtube'))
				{
					if (videoData.embedUrl.includes('?'))
						src = `${videoData.embedUrl}&enablejsapi=1`;
					else
						src = `${videoData.embedUrl}?enablejsapi=1`;
				}
				else
				{
					src = videoData.embedUrl;
				}
				return (
					<div className="mb-4" key={index}>
						<ResponsiveIframe
							aria-label="Embedded video"
							src={src}
							data-consent-src={src}
							data-category-consent="cookie_cat_marketing"
						>
							<button
								className="consent-placeholder border border-grey p-3 m-6 absolute top-0 left-0 hover:cursor-pointer hover:bg-greyLight"
								data-category="cookie_cat_marketing"
								onClick={handleClick}
							>
								{videoData.missingConsentText}
							</button>
						</ResponsiveIframe>
					</div>
				);
			case 'InfoBox':
				const infoBoxData = fragment as InfoBoxFragmentModel;
				return (
					<div className="mt-10 mb-10" key={index}>
						<InfoBox
							heading={infoBoxData.heading}
							variant={infoBoxData.style}
							tooltipText={infoBoxData.tooltipText}
							tooltipLabel={infoBoxData.tooltipLabel}
							copyToClipboardText={infoBoxData.copyToClipboardText}
						>
							<EpiFragments fragments={infoBoxData.text?.fragments} />
						</InfoBox>
					</div>
				);
			case 'ReferencesBox':
				const referencesData = fragment as ReferencesBoxFragmentModel;
				return (
					<div className="mt-10 mb-10" key={index}>
						<InfoBox heading={referencesData.heading} variant="references">
							<EpiFragments fragments={referencesData.text?.fragments} />
						</InfoBox>
					</div>
				);
			case 'ReferencesAccordion':
				const accordionData = fragment as ReferencesAccordionFragmentModel;
				return (
					<div className="mt-10 mb-10" key={index}>
						<ReferenceAccordion
							heading={accordionData.heading}
							headingLevel={accordionData.headingLevel ?? 'h3'}
						>
							<EpiFragments fragments={accordionData.text?.fragments} />
						</ReferenceAccordion>
					</div>
				);
			case 'GuidanceInfoBox':
				const guidanceInfoBoxData = fragment as GuidanceInfoBoxFragmentModel;
				return (
					<div className="mt-10 mb-10" key={index}>
						<GuidanceBox
							text={guidanceInfoBoxData.text}
							url={guidanceInfoBoxData.link?.href}
							linkText={guidanceInfoBoxData.link?.text}
							src={guidanceInfoBoxData.image}
							alt={guidanceInfoBoxData.alt}
						/>
					</div>
				);
			case 'TableFragment':
				const tableData = fragment as TableFragmentModel;
				return (
					<div className="mt-10 mb-10 richtext" key={index}>
						<div
							className={clsx(
								'table-scrollable',
								tableData.stickyColumn && 'table-sticky-col'
							)}
							dangerouslySetInnerHTML={{ __html: tableData.raw }}
						/>
					</div>
				);
			case 'RelatedDialogsModel':
				const dialogData = fragment as RelatedDialogsModel;
				return (
					<QuestionList key={index}>
						{dialogData.heading && (
							<QuestionListHeader header={dialogData.heading}>
								<EpiFragments fragments={dialogData.text?.fragments} />
							</QuestionListHeader>
						)}
						{dialogData.dialogs.map((dialog, index) => (
							<QuestionListItem
								url={dialog.url}
								heading={dialog.heading}
								key={index}
							/>
						))}
					</QuestionList>
				);
			case 'RawFragment':
				return (
					<RichText key={index}>
						<div
							dangerouslySetInnerHTML={{
								__html: (fragment as RawFragmentModel).raw,
							}}
						/>
					</RichText>
				);
			case 'FormContainer':
				const formData = fragment as FormContainerFragmentModel;
				return <EpiForms key={index} {...formData} />;

			case 'EmploymentRequirementsCalculationForm':
				const ekoFormData =
					fragment as EmploymentRequirementsCalculationFormModel;
				return <EkoForm apiUrl={ekoFormData.apiUrl} key={index} />;
			case 'Chapter':
				const chapterData = fragment as ChapterFragmentModel;
				return (
					<div id={chapterData.id} key={index}>
						{chapterData.text.fragments?.map(getFragment)}
					</div>
				);
			case 'Quote':
				const quoteData = fragment as QuoteFragmentModel;
				return (
					<div className="my-12" key={index}>
						<Quote
							author={quoteData.author}
							authorInfo={quoteData.authorInformation}
						>
							{quoteData.text}
						</Quote>
					</div>
				);
			case 'DescriptionList':
				const descriptionListData = fragment as DescriptionListFragmentModel;
				return (
					<DescriptionList
						key={index}
						lineBreak={descriptionListData.lineBreak}
					>
						{descriptionListData.sections.map(
							(section, indexDescriptionWrapper) => {
								switch (section.type) {
									case 'text':
										return (
											<DescriptionWrapper key={indexDescriptionWrapper}>
												<DescriptionTerm
													text={section.heading}
													fontWeight={!section.bold ? 'regular' : 'bold'}
												/>
												<DescriptionText>
													<div
														className="richtext"
														dangerouslySetInnerHTML={{
															__html: section?.text || '',
														}}
													/>
												</DescriptionText>
											</DescriptionWrapper>
										);
									case 'breadcrumb':
										return (
											<DescriptionWrapper key={indexDescriptionWrapper}>
												<DescriptionTerm
													text={section.heading}
													fontWeight={!section.bold ? 'regular' : 'bold'}
												/>
												<DescriptionText>
													{section.items?.map((item, indexDescriptionItem) => (
														<React.Fragment key={indexDescriptionItem}>
															<Link className="text-teal group" to={item.url}>
																<span className="group-hover:underline">
																	{item.text}
																</span>
															</Link>
															{section.items &&
																indexDescriptionItem !==
																	section.items.length - 1 &&
																' / '}
														</React.Fragment>
													))}
												</DescriptionText>
											</DescriptionWrapper>
										);
									case 'listWithLinks':
										return (
											<DescriptionWrapper key={indexDescriptionWrapper}>
												<DescriptionTerm
													text={section.heading}
													fontWeight={!section.bold ? 'regular' : 'bold'}
												/>
												<DescriptionText>
													{section.items && (
														<LinkList>
															{section.items.map(
																(item, indexDescriptionItem) => (
																	<LinkListItem
																		image={item.image}
																		url={item.url}
																		text={item.text}
																		LinkComponent={Link}
																		key={indexDescriptionItem}
																		external={item.external}
																	/>
																)
															)}
														</LinkList>
													)}
												</DescriptionText>
											</DescriptionWrapper>
										);
									default:
										return null;
								}
							}
						)}
					</DescriptionList>
				);
			case 'LinkList':
				const linkListData = fragment as LinkListFragmentModel;
				return (
					<LinkList key={index}>
						{linkListData.items.map((item, indexLinkList) => (
							<LinkListItem
								text={item.text}
								url={item.url}
								icon={item.icon}
								LinkComponent={Link}
								key={indexLinkList}
								external={item.external}
							/>
						))}
					</LinkList>
				);
			case 'ServiceButton':
				const buttonData = fragment as ServiceButtonFragmentModel;
				return (
					<Button
						as="a"
						LinkComponent={Link}
						href={buttonData.url}
						color={buttonData.color}
						className="service-button"
						key={index}
					>
						{buttonData.icon && (
							<span>
								<Icon
									icon={buttonData.icon}
									size={1}
									className="mr-2 -mt-1"
									aria-hidden={true}
								/>
							</span>
						)}
						{buttonData.text}
						<span>
							<Icon
								icon="chevron"
								direction="left"
								size={0.75}
								className="ml-1"
								aria-hidden={true}
							/>
						</span>
					</Button>
				);
			case 'RequirementLevelComparerModel':
				const levelComparerData = fragment as RequirementLevelComparerModel;
				return (
					<React.Fragment key={index}>
						<Text as="h2">{levelComparerData.heading}</Text>
						<CriteriaLevelSelect
							showDetailsText={levelComparerData.showDetailsText}
							hideDetailsText={levelComparerData.hideDetailsText}
						>
							{levelComparerData.levels.map((level, index) => (
								<CriteriaLevel
									title={level.heading}
									url={level.url}
									active={level.isActive}
									key={index}
								>
									{level.rows &&
										level.rows.length > 0 &&
										level.rows.map((row, index) => {
											switch (row.modelType) {
												case 'CompareStringProperty':
													return (
														<Text as="p" key={index}>
															{row.text}
														</Text>
													);
												case 'CompareHtmlProperty':
													return (
														<RichText key={index}>
															<div
																dangerouslySetInnerHTML={{
																	__html:
																		(row as RequirementLevelRowModel).html ||
																		'',
																}}
															/>
														</RichText>
													);
												default:
													return null;
											}
										})}
								</CriteriaLevel>
							))}
						</CriteriaLevelSelect>
					</React.Fragment>
				);
			case 'InfoBoxes':
				const infoBoxesData = fragment as InfoBoxesModel;
				return (
					<Grid
						padding={false}
						margin={false}
						className="pt-16 pb-6"
						key={index}
					>
						{infoBoxesData.boxes &&
							infoBoxesData.boxes.length > 0 &&
							infoBoxesData.boxes.map((box, index) => {
								return (
									<Cell span={12} desktop={4} key={index}>
										<div className="mb-6">
											<InfoBox heading={box.heading} variant="small">
												<EpiFragments fragments={box.text?.fragments} />
											</InfoBox>
										</div>
									</Cell>
								);
							})}
					</Grid>
				);

			case 'InfoBoxCriteria':
				const infoBoxCriteriaData = fragment as InfoBoxCriteriaModel;
				return (
					<>
						{infoBoxCriteriaData.items.map((item, index) => {
							return (
								<InfoBoxCriteria
									heading={item.heading}
									headingLevel={getHeadingLevel(item.level)}
									key={index}
									className="my-12"
								>
									{item.fragments && item.fragments.length > 0 && (
										<EpiFragments fragments={item.fragments} />
									)}
									{item.items &&
										item.items.length > 0 &&
										item.items.map((subItem, subIndex) => {
											return (
												<InfoBoxCriteriaItem
													heading={subItem.heading}
													headingLevel={getHeadingLevel(subItem.level)}
													subHeading={subItem.subHeading}
													url={subItem.url}
													linkText={subItem.linkText}
													LinkComponent={Link}
													key={subIndex}
												>
													{subItem.fragments &&
														subItem.fragments.length > 0 && (
															<EpiFragments fragments={subItem.fragments} />
														)}
												</InfoBoxCriteriaItem>
											);
										})}
								</InfoBoxCriteria>
							);
						})}
					</>
				);
			case 'RiskAssessmentsMatrix':
				const matrixData = fragment as RiskAssessmentsMatrixModel;
				return (
					<RiskTable
						key={index}
						caption={matrixData.caption}
						rowHeading={matrixData.rowHeading}
						columnHeading={matrixData.columnHeading}
						assessments={matrixData.assessments}
						className="my-12"
					/>
				);

			case 'RiskAssessmentsMatrixSmall':
				const matrixSmallData = fragment as RiskAssessmentsMatrixSmallModel;
				return (
					<RiskTable
						key={index}
						variant="small"
						caption={matrixSmallData.caption}
						columnHeading={matrixSmallData.heading}
						assessments={matrixSmallData.assessments}
						className="my-12"
					/>
				);

			case 'ClimateImpactEvaluationForm':
				return <ClimateImpactEvaluationForm />;

			case 'Highcharts':
				const highchartsData = fragment as HighchartsModel;

				return (
					<div className="mb-10">
						{highchartsData.chartProperties ? (
							<StatisticChart
								heading={highchartsData.heading}
								source={highchartsData.source}
								chartProperties={highchartsData.chartProperties}
							/>
						) : highchartsData.tableProperties ? (
							<div>
								{highchartsData.tableProperties.exports && (
									<div className="flex flex-row-reverse mb-4">
										<ExportDataDropdown
											buttonText={
												highchartsData.tableProperties.exports.placeholder
											}
											dataTitle={highchartsData.tableProperties.title}
										>
											{highchartsData.tableProperties.exports.options.map(
												(
													option: StatisticsDataExportOptionModel,
													subindex: number
												) => {
													return (
														<ExportDataOption
															type={option.type}
															url={option.url}
															key={subindex}
														>
															{option.name}
														</ExportDataOption>
													);
												}
											)}
										</ExportDataDropdown>
									</div>
								)}
								<StatisticTable
									data={highchartsData.tableProperties}
									matrix={highchartsData.tableProperties.matrix}
									source={highchartsData.source}
									key={index}
								/>
							</div>
						) : null}
						{highchartsData.text && (
							<EpiFragments
								prop="text mb-4"
								fragments={highchartsData.text?.fragments}
							/>
						)}
					</div>
				);
			case 'StatisticsDataBlock':
				const statisticsData = fragment as StatisticsDataBlockModel;
				return (
					<StatisticsDataBlock
						heading={statisticsData.heading}
						tableData={statisticsData.table}
						diagramData={statisticsData.diagram}
						id={statisticsData.id}
						className="mb-8"
						key={index}
					/>
				);
			default:
				return null;
		}
	};

	const epi = useSelector(selectEpi);
	const epiProps: any = {};

	if (epi?.beta?.isEditable && prop) {
		epiProps['data-epi-property-name'] = prop;
		epiProps['data-epi-property-render'] = 'none';
	}

	return (
		<div {...epiProps} className={className}>
			{fragments?.map(getFragment)}
		</div>
	);
};

export default EpiFragments;
